<template>
    <div>
        <Navbar />
        <PageTitle subTitle="Blog Details" heding="How is technology working with new things?" />
        <BlogDetails />
        <FooterStyleTwo />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import BlogDetails from '../BlogDetails/BlogDetails'
import FooterStyleTwo from '../Layouts/FooterStyleTwo'

export default {
    name: 'BlogDetailsPage',
    components: {
        Navbar,
        PageTitle,
        BlogDetails,
        FooterStyleTwo,
    }
}
</script>