<template>
    <div>
        <Navbar />
        <MainBanner />
        <HowCanHelp />
        <Screenshots />
        <Partner />
        <SoftwareIntegrations />
        <Funfacts />
        <Testimonials />
        <Pricing />
        <Faq class="with-top-border" />
        <Blog />
        <AppDownload />
        <FooterStyleNine />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../Software/MainBanner'
import HowCanHelp from '../Software/HowCanHelp'
import Screenshots from '../Software/Screenshots'
import Partner from '../Software/Partner'
import SoftwareIntegrations from '../Software/SoftwareIntegrations'
import Funfacts from '../Software/Funfacts'
import Testimonials from '../Common/Testimonials'
import Pricing from '../Software/Pricing'
import Faq from '../Common/Faq'
import Blog from '../Common/Blog'
import AppDownload from '../Software/AppDownload'
import FooterStyleNine from '../Layouts/FooterStyleNine'

export default {
    name: 'SoftwarePage',
    components: {
        Navbar,
        MainBanner,
        HowCanHelp,
        Screenshots,
        Partner,
        SoftwareIntegrations,
        Funfacts,
        Testimonials,
        Pricing,
        Faq,
        Blog,
        AppDownload,
        FooterStyleNine,
    }
}
</script>