<template>
    <div class="pricing-area bg-f1f5fd pb-75">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-pricing-box">
                        <h3>Basic Team</h3>
                        <p>Powerful & awesome elements</p>
                        <div class="price">
                            $29<span>/month</span>
                        </div>
                        <router-link to="/contact" class="btn-style-one light-green-color">Purchage Plan <i class="ph-caret-right"></i></router-link>
                        <ul class="features-list">
                            <li>
                                <i class="flaticon-draw-check-mark"></i>
                                Up to 10 Website
                            </li>
                            <li>
                                <i class="flaticon-draw-check-mark"></i>
                                Lifetime Free Support
                            </li>
                            <li>
                                <i class="flaticon-draw-check-mark"></i>
                                10 GB Dedicated Hosting Free
                            </li>
                            <li>
                                <i class="flaticon-draw-check-mark"></i>
                                24/7 Support
                            </li>
                            <li class="close">
                                <i class="flaticon-draw-check-mark"></i>
                                SEO Optimized
                            </li>
                            <li class="close">
                                <i class="flaticon-cancel"></i>
                                Live Support
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-pricing-box">
                        <h3>Small Team</h3>
                        <p>Powerful & awesome elements</p>
                        <div class="price">
                            $49<span>/month</span>
                        </div>
                        <router-link to="/contact" class="btn-style-one light-green-color">Purchage Plan <i class="ph-caret-right"></i></router-link>
                        <ul class="features-list">
                            <li>
                                <i class="flaticon-draw-check-mark"></i>
                                Up to 10 Website
                            </li>
                            <li>
                                <i class="flaticon-draw-check-mark"></i>
                                Lifetime Free Support
                            </li>
                            <li>
                                <i class="flaticon-draw-check-mark"></i>
                                10 GB Dedicated Hosting Free
                            </li>
                            <li>
                                <i class="flaticon-draw-check-mark"></i>
                                24/7 Support
                            </li>
                            <li>
                                <i class="flaticon-draw-check-mark"></i>
                                SEO Optimized
                            </li>
                            <li class="close">
                                <i class="flaticon-cancel"></i>
                                Live Support
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-pricing-box">
                        <h3>Business Plan</h3>
                        <p>Powerful & awesome elements</p>
                        <div class="price">
                            $69<span>/month</span>
                        </div>
                        <router-link to="/contact" class="btn-style-one light-green-color">Purchage Plan <i class="ph-caret-right"></i></router-link>
                        <ul class="features-list">
                            <li>
                                <i class="flaticon-draw-check-mark"></i>
                                Up to 80 Website
                            </li>
                            <li>
                                <i class="flaticon-draw-check-mark"></i>
                                Lifetime Free Support
                            </li>
                            <li>
                                <i class="flaticon-draw-check-mark"></i>
                                80 GB Dedicated Hosting Free
                            </li>
                            <li>
                                <i class="flaticon-draw-check-mark"></i>
                                24/7 Support
                            </li>
                            <li>
                                <i class="flaticon-draw-check-mark"></i>
                                SEO Optimized
                            </li>
                            <li>
                                <i class="flaticon-draw-check-mark"></i>
                                Live Support
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pricing'
}
</script>