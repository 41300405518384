<template>
    <div>
        <Navbar />
        <div class="page-title-area">
            <div class="container">
                <div class="page-title-content">
                    <h1>Finance Consulting</h1>
                    <ul>
                        <li><router-link to="/">Home</router-link></li>
                        <li><router-link to="/portfolio">Portfolio</router-link></li>
                        <li>Finance Consulting</li>
                    </ul>
                </div>
            </div>
        </div>
        <PortfolioDetails />
        <FooterStyleTwo />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PortfolioDetails from '../PortfolioDetails/PortfolioDetails'
import FooterStyleTwo from '../Layouts/FooterStyleTwo'

export default {
    name: 'PortfolioDetailsPage',
    components: {
        Navbar,
        PortfolioDetails,
        FooterStyleTwo,
    }
}
</script>