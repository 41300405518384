<template>
    <div>
        <Navbar />
        <PageTitle subTitle="Latest News" heding="Our latest articles & resources" />
        <BlogGrid />
        <FooterStyleTwo />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import BlogGrid from '../BlogGrid/BlogGrid'
import FooterStyleTwo from '../Layouts/FooterStyleTwo'

export default {
    name: 'BlogGridPage',
    components: {
        Navbar,
        PageTitle,
        BlogGrid,
        FooterStyleTwo,
    }
}
</script>