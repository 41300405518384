<template>
    <div class="template-footer-seven pt-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="single-footer-widget">
                        <router-link to="/" class="logo">
                            <img src="../../assets/images/logo.png" alt="logo">
                        </router-link>
                        <p>Lorem ipsum dolor sit amet, consectetur, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua minim.</p>
                        <ul class="social-links">
                            <li>
                                <a href="https://www.https://www.linkedin.com/.com/" target="_blank">
                                    <i class="flaticon-facebook-app-symbol"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.twitter.com/" target="_blank">
                                    <i class="flaticon-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/" target="_blank">
                                    <i class="flaticon-linkedin"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/" target="_blank">
                                    <i class="flaticon-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                    <div class="single-footer-widget">
                        <h3 class="nunito-font">Resources</h3>
                        <ul class="quick-links">
                            <li><router-link to="/portfolio-details">Business Startup</router-link></li>
                            <li><router-link to="/portfolio-details">Finance Consulting</router-link></li>
                            <li><router-link to="/portfolio-details">Wealth Management</router-link></li>
                            <li><router-link to="/portfolio-details">Business Planning</router-link></li>
                            <li><router-link to="/portfolio-details">Data Management</router-link></li>
                            <li><router-link to="/portfolio-details">Marketing Planning</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                    <div class="single-footer-widget">
                        <h3 class="nunito-font">About Us</h3>
                        <ul class="quick-links">
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/about-us">About Us</router-link></li>
                            <li><router-link to="/blog-grid">Latest News</router-link></li>
                            <li><router-link to="/contact">Contact Us</router-link></li>
                            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            <li><router-link to="/terms-conditions">Terms & Condition</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-footer-widget">
                        <h3 class="nunito-font">Contact Info</h3>
                        <ul class="footer-contact-info">
                            <li>
                                <i class="ph-phone-call"></i>
                                <span>Mon to Fri : 10:00AM - 06:00PM</span>
                                <a href="tel:1235421457852">+123 54214 578 52</a>
                            </li>
                            <li>
                                <i class="ph-envelope-simple-open"></i>
                                <span>Do You Have a Question?</span>
                                <a href="mailto:hello@vumy.com">hello@vumy.com</a>
                            </li>
                            <li>
                                <i class="ph-map-pin"></i>
                                <span>2750 Quadra Street Victoria, Canada</span>
                                <a href="#" target="_blank">Find Us on Map</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="container">
                <p>
                    Copyright @{{currentYear}} Vumy. All Rights Reserved by 
                    <a href="https://envytheme.com/" target="_blank">EnvyTheme</a>
                </p>
            </div>
        </div>
        <div class="shape5"><img src="../../assets/images/shape/shape5.png" alt="shape"></div>
    </div>
</template>

<script>
export default {
    name: 'FooterStyleSeven',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>