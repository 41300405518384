<template>
    <div>
        <Navbar />
        <PageTitle subTitle="Shop" heding="Shopping Cart" />
        <Cart />
        <FooterStyleTwo />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Cart from '../Cart/Cart'
import FooterStyleTwo from '../Layouts/FooterStyleTwo'

export default {
    name: 'CartPage',
    components: {
        Navbar,
        PageTitle,
        Cart,
        FooterStyleTwo,
    }
}
</script>