<template>
    <div class="business-banner-area">
        <div class="container-fluid">
            <div class="business-banner-content">
                <h1 data-aos="fade-up" data-aos-duration="1200">
                    Ready to take your business growth to the next level?
                </h1>
                <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">Velit hendrerit sit auctor tempor sem. Congue mi tempor condimentum felis arcu, non cursus. Nulla pharetra porttitor sed platea arcu et leo odio.</p>
                <div class="btn-box" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <router-link to="/contact" class="btn-style-one red-light-color">
                        Get Started Now 
                        <i class="ph-caret-right"></i>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>