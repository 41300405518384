<template>
    <div class="features-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span class="sub-title dark-green-color">Key Features</span>
                <h2 class="nunito-font">Most probably included best features ever</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-features-item">
                        <div class="icon">
                            <i class="flaticon-beach-umbrella"></i>
                        </div>
                        <h3 class="nunito-font">More Coverage</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-features-item">
                        <div class="icon">
                            <i class="flaticon-ok"></i>
                        </div>
                        <h3 class="nunito-font">Hassle Free</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat feu sagittis, donec.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-features-item">
                        <div class="icon">
                            <i class="flaticon-fast-time"></i>
                        </div>
                        <h3 class="nunito-font">Faster Benefits</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat feu sagittis, donec.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>