<template>
    <div>
        <NavbarStyleSeven />
        <MainBanner />
        <Features />
        <DevelopSkill />
        <Courses />
        <RegistrationProcess />
        <Testimonials />
        <Funfacts />
        <Blog />
        <Partner />
        <AppDownload />
        <FooterStyleSeven />
    </div>
</template>

<script>
import NavbarStyleSeven from '../Layouts/NavbarStyleSeven'
import MainBanner from '../DistanceLearning/MainBanner'
import Features from '../DistanceLearning/Features'
import DevelopSkill from '../DistanceLearning/DevelopSkill'
import Courses from '../DistanceLearning/Courses'
import RegistrationProcess from '../DistanceLearning/RegistrationProcess'
import Testimonials from '../DistanceLearning/Testimonials'
import Funfacts from '../DistanceLearning/Funfacts'
import Blog from '../DistanceLearning/Blog'
import Partner from '../DistanceLearning/Partner'
import AppDownload from '../DistanceLearning/AppDownload'
import FooterStyleSeven from '../Layouts/FooterStyleSeven'

export default {
    name: 'DistanceLearningPage',
    components: {
        NavbarStyleSeven,
        MainBanner,
        Features,
        DevelopSkill,
        Courses,
        RegistrationProcess,
        Testimonials,
        Funfacts,
        Blog,
        Partner,
        AppDownload,
        FooterStyleSeven,
    }
}
</script>