<template>
    <div>
        <Navbar />
        <PageTitle subTitle="Shop" heding="Shopping Checkout" />
        <Checkout />
        <FooterStyleTwo />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Checkout from '../Checkout/Checkout'
import FooterStyleTwo from '../Layouts/FooterStyleTwo'

export default {
    name: 'CheckoutPage',
    components: {
        Navbar,
        PageTitle,
        Checkout,
        FooterStyleTwo,
    }
}
</script>