<template>
    <div class="gallery-area pt-100 pb-75 bg-f9f9f9">
        <div class="container">
            <div class="section-title text-start" data-aos="fade-up" data-aos-duration="1200">
                <span class="sub-title green-color">Photo Gallery</span>
                <h2 class="nunito-font">Quality living from the team that cares</h2>
            </div>
            <div class="gallery-slides">
                <lightgallery
                    :settings="{ speed: 500, plugins: plugins }"
                    :onInit="onInit"
                    :onBeforeSlide="onBeforeSlide"
                    class="row justify-content-center"
                >
                    <a
                        v-for="item in items"
                        :key="item.id"
                        :data-lg-size="item.size"
                        className="gallery-item"
                        :data-src="item.src"
                        class="col-lg-4 col-md-6 col-sm-6"
                    >
                        <div class="single-gallery-item">
                            <img className="img-responsive" :src="item.thumb" />
                        </div>
                    </a>
                </lightgallery>
            </div>
        </div>
    </div>
</template>

<script>
import Lightgallery from 'lightgallery/vue';
import lgZoom from 'lightgallery/plugins/zoom';
let lightGallery= null;

export default {
    name: 'Gallery',
    components: {
        Lightgallery,
    },
    watch: {
        items() {
            this.$nextTick(() => {
                lightGallery.refresh();
            });
        },
    },
    data: () => ({
        plugins: [lgZoom],
        items: [
            {
                id: '1',
                src: require('../../assets/images/gallery/gallery1.jpg'),
                thumb: require('../../assets/images/gallery/gallery1.jpg'),
            },
            {
                id: '2',
                src: require('../../assets/images/gallery/gallery2.jpg'),
                thumb: require('../../assets/images/gallery/gallery2.jpg'),
            },
            {
                id: '3',
                src: require('../../assets/images/gallery/gallery3.jpg'),
                thumb: require('../../assets/images/gallery/gallery3.jpg'),
            },
        ],
    }),
    methods: {
        onInit: (detail) => {
            lightGallery = detail.instance;
        },
        onBeforeSlide: () => {
            console.log('calling before slide');
        },
    },
}
</script>

<style lang="css">
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.1.0-beta.1/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.1.0-beta.1/css/lg-zoom.css');
</style>