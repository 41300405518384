<template>
    <div class="overview-area ptb-100 bg-f9f9f9">
        <div class="container">
            <div class="section-title">
                <span class="sub-title green-color">Services We Offer</span>
                <h2>We provide high-impact medical services</h2>
            </div>
            <div class="overview-item-style-two">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 overview-image" data-aos="fade-up" data-aos-duration="1200">
                        <img src="../../assets/images/overview/overview9.png" alt="overview-image">
                    </div>
                    <div class="col-lg-6 col-md-12 overview-content">
                        <h2 class="nunito-font">Cardiology department</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3 class="nunito-font"><a href="#">Cardiac Rehabilitation</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3 class="nunito-font"><a href="#">Stress Testing</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3 class="nunito-font"><a href="#">Stenting</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3 class="nunito-font"><a href="#">Cardiac Catheterization</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="overview-item-style-two">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 overview-content">
                        <h2 class="nunito-font">Neurosurgery department</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3 class="nunito-font"><a href="#">Surgical Oncology</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3 class="nunito-font"><a href="#">Neuro-Oncology</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3 class="nunito-font"><a href="#">Brain Stimulators</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3 class="nunito-font"><a href="#">Interventional Treatment</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-12 overview-image" data-aos="fade-up" data-aos-duration="1200">
                        <img src="../../assets/images/overview/overview10.png" alt="overview-image">
                    </div>
                </div>
            </div>
            <div class="overview-item-style-two">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 overview-image" data-aos="fade-up" data-aos-duration="1200">
                        <img src="../../assets/images/overview/overview11.png" alt="overview-image">
                    </div>
                    <div class="col-lg-6 col-md-12 overview-content">
                        <h2 class="nunito-font">Dental department</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3 class="nunito-font"><a href="#">Dental Bridge</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3 class="nunito-font"><a href="#">Dental Implant</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3 class="nunito-font"><a href="#">magnetic Implant</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3 class="nunito-font"><a href="#">Cavity Filling</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>