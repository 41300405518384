<template>
    <div>
        <Navbar />
        <PageTitle subTitle="Blog left sidebar" heding="Our latest articles & resources" />
        <BlogLeftSidebar />
        <FooterStyleTwo />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import BlogLeftSidebar from '../BlogLeftSidebar/BlogLeftSidebar'
import FooterStyleTwo from '../Layouts/FooterStyleTwo'

export default {
    name: 'BlogLeftSidebarPage',
    components: {
        Navbar,
        PageTitle,
        BlogLeftSidebar,
        FooterStyleTwo,
    }
}
</script>