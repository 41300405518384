<template>
    <div class="working-process-area bg-1d2b53">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-7 col-md-12">
                    <div class="working-process-content style-two">
                        <span class="sub-title">Registration Process</span>
                        <h2 class="nunito-font">Dedicated to help anything people’s needs</h2>
                        <ul class="working-process-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <div class="number">
                                    1
                                </div>
                                <h3>Select Suitable Course</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div class="number">
                                    2
                                </div>
                                <h3>Payment Information</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <div class="number">
                                    3
                                </div>
                                <h3>Register Now</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12">
                    <div class="working-process-image style-two bg2">
                        <img src="../../assets/images/working-process3.jpg" alt="working-process">
                    </div>
                </div>
            </div>
        </div>
        <div class="shape15"><img src="../../assets/images/shape/shape15.png" alt="shape"></div>
    </div>
</template>

<script>
export default {
    name: 'RegistrationProcess'
}
</script>