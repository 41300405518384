<template>
    <div class="profile-authentication-area ptb-100">
        <div class="container">
            <div class="lost-password-box">
                <p>Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.</p>
                <form @submit.prevent>
                    <label>Username or email</label>
                    <input type="text" class="form-control">
                    <button type="submit">Reset Password</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ForgotPassword'
}
</script>