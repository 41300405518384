<template>
    <div class="what-we-do-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span class="sub-title purple-color">What We Do</span>
                <h2 class="nunito-font">Our work is delivered by the best team in the world</h2>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-what-we-do-box purple-color">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon9.png" alt="icon">
                        </div>
                        <h3><router-link to="/services-details">Incredible Infrastructure</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, conse ctetur adipi scing elit sed est. Our work is delivered by the best team in the world.</p>
                        <router-link to="/services-details" class="link-btn">
                            Learn More 
                            <i class="ph-caret-right"></i>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-what-we-do-box purple-color">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon10.png" alt="icon">
                        </div>
                        <h3><router-link to="/services-details">Deadline Reminders</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, conse ctetur adipi scing elit sed est. Our work is delivered by the best team in the world.</p>
                        <router-link to="/services-details" class="link-btn">
                            Learn More 
                            <i class="ph-caret-right"></i>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-what-we-do-box purple-color">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon11.png" alt="icon">
                        </div>
                        <h3><router-link to="/services-details">Information Retrieval</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, conse ctetur adipi scing elit sed est. Our work is delivered by the best team in the world.</p>
                        <router-link to="/services-details" class="link-btn">
                            Learn More 
                            <i class="ph-caret-right"></i>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-what-we-do-box purple-color">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon12.png" alt="icon">
                        </div>
                        <h3><router-link to="/services-details">Simple Dashboard</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, conse ctetur adipi scing elit sed est. Our work is delivered by the best team in the world.</p>
                        <router-link to="/services-details" class="link-btn">
                            Learn More 
                            <i class="ph-caret-right"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhatWeDo'
}
</script>