<template>
    <div>
        <NavbarStyleFive />
        <PageTitle subTitle="Blog Details" heding="How our company works in different ways" />
        <BlogDetailsThree />
        <FooterStyleTwo />
    </div>
</template>

<script>
import NavbarStyleFive from '../Layouts/NavbarStyleFive'
import PageTitle from '../Common/PageTitle'
import BlogDetailsThree from '../BlogDetailsThree/BlogDetailsThree'
import FooterStyleTwo from '../Layouts/FooterStyleTwo'

export default {
    name: 'BlogDetailsThreePage',
    components: {
        NavbarStyleFive,
        PageTitle,
        BlogDetailsThree,
        FooterStyleTwo,
    }
}
</script>