<template>
    <div class="team-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="team-member-list-style-two">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                                <div class="single-team-member bg1">
                                    <img src="../../assets/images/team/team7.jpg" alt="member-image">
                                    <div class="content">
                                        <h3>Franco Gino</h3>
                                        <ul class="social">
                                            <li>
                                                <a href="https://www.https://www.linkedin.com/.com/" target="_blank">
                                                    <i class="flaticon-facebook-app-symbol"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.twitter.com/" target="_blank">
                                                    <i class="flaticon-twitter"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.linkedin.com/" target="_blank">
                                                    <i class="flaticon-linkedin"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/" target="_blank">
                                                    <i class="flaticon-instagram"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div class="single-team-member bg2">
                                    <img src="../../assets/images/team/team8.jpg" alt="member-image">
                                    <div class="content">
                                        <h3>Emila Lucy</h3>
                                        <ul class="social">
                                            <li>
                                                <a href="https://www.https://www.linkedin.com/.com/" target="_blank">
                                                    <i class="flaticon-facebook-app-symbol"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.twitter.com/" target="_blank">
                                                    <i class="flaticon-twitter"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.linkedin.com/" target="_blank">
                                                    <i class="flaticon-linkedin"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/" target="_blank">
                                                    <i class="flaticon-instagram"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                                <div class="single-team-member bg3">
                                    <img src="../../assets/images/team/team9.jpg" alt="member-image">
                                    <div class="content">
                                        <h3>Alina Smith</h3>
                                        <ul class="social">
                                            <li>
                                                <a href="https://www.https://www.linkedin.com/.com/" target="_blank">
                                                    <i class="flaticon-facebook-app-symbol"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.twitter.com/" target="_blank">
                                                    <i class="flaticon-twitter"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.linkedin.com/" target="_blank">
                                                    <i class="flaticon-linkedin"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/" target="_blank">
                                                    <i class="flaticon-instagram"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div class="single-team-member bg4">
                                    <img src="../../assets/images/team/team10.jpg" alt="member-image">
                                    <div class="content">
                                        <h3>Andrea Romeo</h3>
                                        <ul class="social">
                                            <li>
                                                <a href="https://www.https://www.linkedin.com/.com/" target="_blank">
                                                    <i class="flaticon-facebook-app-symbol"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.twitter.com/" target="_blank">
                                                    <i class="flaticon-twitter"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.linkedin.com/" target="_blank">
                                                    <i class="flaticon-linkedin"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/" target="_blank">
                                                    <i class="flaticon-instagram"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="shape4"><img src="../../assets/images/shape/shape4.png" alt="shape"></div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="team-content style-two">
                        <span class="sub-title">Our Creative Team</span>
                        <h2>Our team believes you deserve only the best</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus auctor purus risus, eu vitae neque, a platea sit. Dui nisi tempus in ac arcu. In neque laoreet mi malesuada quam morbi. Nisl sed a risus vitae, platea eget. Tortor, nisl aliquam urna dignissim.</p>
                        <router-link to="/team" class="btn-style-one red-light-color">
                            Meet Our Team 
                            <i class="ph-caret-right"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Team'
}
</script>