<template>
    <div class="portfolio-details-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-md-12">
                    <div class="portfolio-details-image">                            
                        <lightgallery
                            :settings="{ speed: 500, plugins: plugins }"
                            :onInit="onInit"
                            :onBeforeSlide="onBeforeSlide"
                            class="row justify-content-center"
                        >
                            <a
                                v-for="item in items"
                                :key="item.id"
                                :data-lg-size="item.size"
                                className="gallery-item"
                                :data-src="item.src"
                                class="col-lg-12 col-md-6 col-sm-6"
                            >
                                <div class="image">
                                    <img className="img-responsive" :src="item.thumb" />
                                </div>
                            </a>
                        </lightgallery>
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="portfolio-details-desc pl-15">
                        <h4>About the Projects</h4>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam <a href="#">nonumy</a> tempor invidunt ut labore et dolore magna erat. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea <strong>takimata</strong> sanctus est Lorem ipsum dolor sit amet.</p>
                        <p>Lorem ipsum dolor sit amet, consetetur <strong>sadipscing</strong> elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. Stet <a href="#">clita</a> kasd gubergren, no sea takimata sanctus est lorem ipsum dolor sit amet.</p>
                        <h4>Projects Information</h4>
                        <ul class="info">
                            <li><span>CLIENT:</span> James Andy</li>
                            <li><span>PUBLISHED:</span> 20 August 2021</li>
                            <li><span>SERVICES:</span> <router-link to="/portfolio">Branding</router-link></li>
                            <li><span>INDUSTRY:</span> <router-link to="/portfolio">Lifestyle</router-link></li>
                            <li><span>LOCATION:</span> 32, Walse Street, New York, United States of America</li>
                            <li><span>WEBSITE:</span> <a href="#" target="_blank">yourdomain.com</a></li>
                        </ul>
                        <h4>Some Of Our Design</h4>
                        <p>Lorem ipsum dolor sit amet, consetetur <strong>sadipscing</strong> elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                        <ul>
                            <li>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.</li>
                            <li>Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.</li>
                            <li>Sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</li>
                        </ul>
                        <a href="#" target="_blank" class="btn-style-one red-light-color">Check Live Link <i class="ph-caret-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Lightgallery from 'lightgallery/vue';
import lgZoom from 'lightgallery/plugins/zoom';
let lightGallery= null;

export default {
    name: 'PortfolioDetails',
    components: {
        Lightgallery,
    },
    watch: {
        items() {
            this.$nextTick(() => {
                lightGallery.refresh();
            });
        },
    },
    data: () => ({
        plugins: [lgZoom],
        items: [
            {
                id: '1',
                src: require('../../assets/images/case-studies/case-studies1.jpg'),
                thumb: require('../../assets/images/case-studies/case-studies1.jpg'),
            },
            {
                id: '2',
                src: require('../../assets/images/case-studies/case-studies2.jpg'),
                thumb: require('../../assets/images/case-studies/case-studies2.jpg'),
            },
        ],
    }),
    methods: {
        onInit: (detail) => {
            lightGallery = detail.instance;
        },
        onBeforeSlide: () => {
            console.log('calling before slide');
        },
    },
}
</script>

<style lang="css">
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.1.0-beta.1/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.1.0-beta.1/css/lg-zoom.css');
</style>