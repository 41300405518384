<template>
    <div class="testimonials-area bg-1d2b53 ptb-100">
        <div class="container">
            <div class="section-title white-color">
                <span class="sub-title">Testimonials</span>
                <h2>Here’s what our amazing clients are saying</h2>
            </div>
            <div class="testimonials-slides">
                <carousel
                    :autoplay="5000"
                    :wrap-around="true"
                >
                    <Slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="single-testimonials-item">
                            <p>{{slide.desc}}</p>
                            <div class="client-info d-flex align-items-center justify-content-center">
                                <img :src="slide.image" alt="user">
                                <div class="info">
                                    <h3>{{slide.name}}</h3>
                                    <span>{{slide.position}}</span>
                                </div>
                            </div>
                        </div>
                    </Slide>

                    <template #addons>
                        <Pagination />
                    </template> 
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Testimonials',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna facilisi viverra felis eleifend ornare urna. Eu mauris, velit volutpat massa volutpat. Risus pellentesque felis nisl ut laoreet euismod vel, integer. Massa sodales lorem nisi, sed. Feugiat eu lacus, tortor egestas ut aenean. Est mauris pulvinar at vulputate.',
                image: require('../../assets/images/user/user1.jpg'),
                name: 'Lora Joly',
                position: 'Founder at Envato',
            },
            {
                id: 2,
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna facilisi viverra felis eleifend ornare urna. Eu mauris, velit volutpat massa volutpat. Risus pellentesque felis nisl ut laoreet euismod vel, integer. Massa sodales lorem nisi, sed. Feugiat eu lacus, tortor egestas ut aenean. Est mauris pulvinar at vulputate.',
                image: require('../../assets/images/user/user2.jpg'),
                name: 'John Smith',
                position: 'Python Developer',
            },
            {
                id: 3,
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna facilisi viverra felis eleifend ornare urna. Eu mauris, velit volutpat massa volutpat. Risus pellentesque felis nisl ut laoreet euismod vel, integer. Massa sodales lorem nisi, sed. Feugiat eu lacus, tortor egestas ut aenean. Est mauris pulvinar at vulputate.',
                image: require('../../assets/images/user/user3.jpg'),
                name: 'David Warner',
                position: 'PQA Developer',
            },
            {
                id: 4,
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna facilisi viverra felis eleifend ornare urna. Eu mauris, velit volutpat massa volutpat. Risus pellentesque felis nisl ut laoreet euismod vel, integer. Massa sodales lorem nisi, sed. Feugiat eu lacus, tortor egestas ut aenean. Est mauris pulvinar at vulputate.',
                image: require('../../assets/images/user/user4.jpg'),
                name: 'James Andy',
                position: 'PHP Developer',
            },
            {
                id: 5,
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna facilisi viverra felis eleifend ornare urna. Eu mauris, velit volutpat massa volutpat. Risus pellentesque felis nisl ut laoreet euismod vel, integer. Massa sodales lorem nisi, sed. Feugiat eu lacus, tortor egestas ut aenean. Est mauris pulvinar at vulputate.',
                image: require('../../assets/images/user/user5.jpg'),
                name: 'John Smith',
                position: 'CEO',
            },
        ],
    }),
})
</script>