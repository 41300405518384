<template>
    <div>
        <NavbarStyleSeven />
        <CoursesDetails />
        <Courses />
        <AppDownload />
        <FooterStyleSeven />
    </div>
</template>

<script>
import NavbarStyleSeven from '../Layouts/NavbarStyleSeven'
import CoursesDetails from '../CoursesDetails/CoursesDetails'
import Courses from '../CoursesDetails/Courses'
import AppDownload from '../CoursesDetails/AppDownload'
import FooterStyleSeven from '../Layouts/FooterStyleSeven'

export default {
    name: 'CoursesDetailsPage',
    components: {
        NavbarStyleSeven,
        CoursesDetails,
        Courses,
        AppDownload,
        FooterStyleSeven,
    }
}
</script>