<template>
    <div>
        <NavbarStyleThree />
        <PageTitle subTitle="Pricing Table" heding="No Hidden Charge Applied, Choose Your Plan" />
        <Pricing />
        <Features />
        <Faq />
        <Testimonials />
        <GetStarted />
        <FooterStyleThree />
    </div>
</template>

<script>
import NavbarStyleThree from '../Layouts/NavbarStyleThree'
import PageTitle from '../Common/PageTitle'
import Pricing from '../Pricing/Pricing'
import Features from '../Pricing/Features'
import Faq from '../Pricing/Faq'
import Testimonials from '../Pricing/Testimonials'
import GetStarted from '../Pricing/GetStarted'
import FooterStyleThree from '../Layouts/FooterStyleThree'

export default {
    name: 'PricingPage',
    components: {
        NavbarStyleThree,
        PageTitle,
        Pricing,
        Features,
        Faq,
        Testimonials,
        GetStarted,
        FooterStyleThree,
    }
}
</script>