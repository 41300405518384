<template>
    <div class="testimonials-area bg-263238 ptb-100">
        <div class="container">
            <div class="section-title white-color" data-aos="fade-up" data-aos-duration="1200">
                <span class="sub-title">Testimonials</span>
                <h2 class="nunito-font">Here’s what our amazing Students are saying</h2>
            </div>
            <div class="testimonials-slides-four">
                <carousel
                    :autoplay="5000"
                    :settings='settings'
                    :breakpoints='breakpoints'
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="testimonials-item">
                            <i class="flaticon-left-quotes-sign"></i>
                            <p>{{slide.desc}}</p>
                            <div class="info">
                                <h3>{{slide.name}}</h3>
                                <span>{{slide.position}}</span>
                            </div>
                            <img :src="slide.image" alt="user">
                        </div>
                    </slide>
                    
                    <template #addons>
                        <Pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Testimonials',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                desc: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna facilisi viverra felis eleifend ornare urna. Eu mauris, velit volutpat massa volutpat. Risus pellentesque felis nisl ut laoreet euismod vel”',
                name: 'Lora Joly',
                position: 'Founder at Envato',
                image: require('../../assets/images/user/user1.jpg'),
            },
            {
                id: 2,
                desc: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna facilisi viverra felis eleifend ornare urna. Eu mauris, velit volutpat massa volutpat. Risus pellentesque felis nisl ut laoreet euismod vel”',
                name: 'Alina Smith',
                position: 'Founder at EnvyTheme',
                image: require('../../assets/images/user/user2.jpg'),
            },
            {
                id: 3,
                desc: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna facilisi viverra felis eleifend ornare urna. Eu mauris, velit volutpat massa volutpat. Risus pellentesque felis nisl ut laoreet euismod vel”',
                name: 'James Andy',
                position: 'Founder at ThemeForest',
                image: require('../../assets/images/user/user3.jpg'),
            },
            {
                id: 4,
                desc: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna facilisi viverra felis eleifend ornare urna. Eu mauris, velit volutpat massa volutpat. Risus pellentesque felis nisl ut laoreet euismod vel”',
                name: 'David Warner',
                position: 'Founder at Code',
                image: require('../../assets/images/user/user4.jpg'),
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
			992: {
                itemsToShow: 2,
                snapAlign: 'center',
			},
            1200: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
        },
    }),
})
</script>