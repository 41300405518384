<template>
    <div class="join-our-community-area bg-e8fcff ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12" data-aos="fade-up" data-aos-duration="1200">
                    <div class="join-our-community-image">
                        <img src="../../assets/images/join-out-community.png" alt="join-out-community">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="join-our-community-content">
                        <span class="sub-title" data-aos="fade-up" data-aos-duration="1200">Join Our Community</span>
                        <h2 class="nunito-font" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">Leading provider of life Insurance solutions</h2>
                        <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id proin <br>lectus in aliquam orci mollis ornare nec commodo.</p>
                        <div class="btn-box" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                            <router-link to="/contact" class="btn-style-one crimson-color">
                                Get Started Now 
                                <i class="ph-caret-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'JoinOurCommunity'
}
</script>