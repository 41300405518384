<template>
    <div class="case-studies-area pb-100 bg-f1f5fd">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-resources-box red-light-color">
                        <router-link to="/portfolio-details" class="d-block image">
                            <img src="../../assets/images/resources/resources1.jpg" alt="resources">
                        </router-link>
                        <div class="content">
                            <h3 class="nunito-font"><router-link to="/portfolio-details">Free Data Sources For Your Next Project</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur mauris amet, placerat fames orci elementum adipiscing consectetur sagittis.</p>
                            <router-link to="/portfolio-details" class="link-btn">Learn More <i class="ph-caret-right"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-resources-box red-light-color">
                        <router-link to="/portfolio-details" class="d-block image">
                            <img src="../../assets/images/resources/resources2.jpg" alt="resources">
                        </router-link>
                        <div class="content">
                            <h3 class="nunito-font"><router-link to="/portfolio-details">Gaining New Insights from Data Lakes</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur mauris amet, placerat fames orci elementum adipiscing consectetur sagittis.</p>
                            <router-link to="/portfolio-details" class="link-btn">Learn More <i class="ph-caret-right"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-resources-box red-light-color">
                        <router-link to="/portfolio-details" class="d-block image">
                            <img src="../../assets/images/resources/resources3.jpg" alt="resources">
                        </router-link>
                        <div class="content">
                            <h3 class="nunito-font"><router-link to="/portfolio-details">Top 10 Data Resources Online in 2022</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur mauris amet, placerat fames orci elementum adipiscing consectetur sagittis.</p>
                            <router-link to="/portfolio-details" class="link-btn">Learn More <i class="ph-caret-right"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-resources-box red-light-color">
                        <router-link to="/portfolio-details" class="d-block image">
                            <img src="../../assets/images/resources/resources4.jpg" alt="resources">
                        </router-link>
                        <div class="content">
                            <h3 class="nunito-font"><router-link to="/portfolio-details">Top 10 important tips on IT Services & Design</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur mauris amet, placerat fames orci elementum adipiscing consectetur sagittis.</p>
                            <router-link to="/portfolio-details" class="link-btn">Learn More <i class="ph-caret-right"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-resources-box red-light-color">
                        <router-link to="/portfolio-details" class="d-block image">
                            <img src="../../assets/images/resources/resources5.jpg" alt="resources">
                        </router-link>
                        <div class="content">
                            <h3 class="nunito-font"><router-link to="/portfolio-details">9 apps to help people sharpen their Coding</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur mauris amet, placerat fames orci elementum adipiscing consectetur sagittis.</p>
                            <router-link to="/portfolio-details" class="link-btn">Learn More <i class="ph-caret-right"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-resources-box red-light-color">
                        <router-link to="/portfolio-details" class="d-block image">
                            <img src="../../assets/images/resources/resources6.jpg" alt="resources">
                        </router-link>
                        <div class="content">
                            <h3 class="nunito-font"><router-link to="/portfolio-details">Bootstrap 5 is open source software you can use</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur mauris amet, placerat fames orci elementum adipiscing consectetur sagittis.</p>
                            <router-link to="/portfolio-details" class="link-btn">Learn More <i class="ph-caret-right"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12" data-aos="fade-up" data-aos-duration="1200">
                    <div class="pagination-area">
                        <div class="nav-links">
                            <a href="/portfolio-two" class="prev page-numbers">prev</a>
                            <span class="page-numbers current">1</span>
                            <a href="/portfolio-two" class="page-numbers">2</a>
                            <a href="/portfolio-two" class="page-numbers">3</a>
                            <a href="/portfolio-two" class="next page-numbers">next</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Portfolio'
}
</script>