<template>
    <div>
        <NavbarStyleTwo />
        <PageTitle class="bg-black" subTitle="Our Services" heding="Our work is delivered by the best team in the world" />
        <HowCanHelp />
        <Services />
        <WorkingProcess />
        <GetStarted />
        <Partner class="ptb-100 bg-fff4f8" />
        <FooterStyleTwo />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layouts/NavbarStyleTwo'
import PageTitle from '../Common/PageTitle'
import HowCanHelp from '../ServicesTwo/HowCanHelp'
import Services from '../ServicesTwo/Services'
import WorkingProcess from '../ServicesTwo/WorkingProcess'
import GetStarted from '../ServicesTwo/GetStarted'
import Partner from '../Common/Partner'
import FooterStyleTwo from '../Layouts/FooterStyleTwo'

export default {
    name: 'ServicesTwoPage',
    components: {
        NavbarStyleTwo,
        PageTitle,
        HowCanHelp,
        Services,
        WorkingProcess,
        GetStarted,
        Partner,
        FooterStyleTwo,
    }
}
</script>