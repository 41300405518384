<template>
    <div class="develop-skill-area bg-fff9ee">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="develop-skill-image">
                        <img src="../../assets/images/develop-skill.jpg" alt="develop-skill">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="develop-skill-content" data-aos="fade-up" data-aos-duration="1200">
                        <span class="sub-title">Education for everyone</span>
                        <h2 class="nunito-font">Develop your skills from anywhere in the world!</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id proin lectus in aliquam orci mollis ornare nec. Commodo morbi tincidunt egestas velit sed.</p>
                        <ul class="skill-list">
                            <li>
                                <div class="icon">
                                    <i class="ph-checks"></i>
                                </div>
                                Expert Trainers
                                <router-link to="/courses" class="link-btn"><i class="ph-caret-right"></i></router-link>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="ph-checks"></i>
                                </div>
                                Lifetime Access
                                <router-link to="/courses" class="link-btn"><i class="ph-caret-right"></i></router-link>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="ph-checks"></i>
                                </div>
                                Remote Learning
                                <router-link to="/courses" class="link-btn"><i class="ph-caret-right"></i></router-link>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="ph-checks"></i>
                                </div>
                                Self Development
                                <router-link to="/courses" class="link-btn"><i class="ph-caret-right"></i></router-link>
                            </li>
                        </ul>
                        <router-link to="/courses" class="btn-style-one yellow-color">
                            View All Courses 
                            <i class="ph-caret-right"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape14"><img src="../../assets/images/shape/shape14.png" alt="shape"></div>
    </div>
</template>

<script>
export default {
    name: 'DevelopSkill'
}
</script>