<template>
    <div class="services-area pt-100 bg-f9f9f9 pb-75">
        <div class="container">
            <div class="section-title">
                <span class="sub-title green-color">What We Offer</span>
                <h2 class="nunito-font">Everyone deserves the opportunity of home</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="services-box">
                        <div class="icon">
                            <i class="flaticon-assets"></i>
                        </div>
                        <h3 class="nunito-font"><router-link to="/services-details">Property Management</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagidyttis, donec.</p>
                        <router-link to="/services-details" class="link-btn">Learn More <i class="ph-caret-right"></i></router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="services-box">
                        <div class="icon">
                            <i class="flaticon-mortgage"></i>
                        </div>
                        <h3 class="nunito-font"><router-link to="/services-details">Finance Real Estate</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagidyttis, donec.</p>
                        <router-link to="/services-details" class="link-btn">Learn More <i class="ph-caret-right"></i></router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="services-box">
                        <div class="icon">
                            <i class="flaticon-challenges"></i>
                        </div>
                        <h3 class="nunito-font"><router-link to="/services-details">Business Development</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagidyttis, donec.</p>
                        <router-link to="/services-details" class="link-btn">Learn More <i class="ph-caret-right"></i></router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="services-box">
                        <div class="icon">
                            <i class="flaticon-gold-bar"></i>
                        </div>
                        <h3 class="nunito-font"><router-link to="/services-details">Recover Asset Value</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagidyttis, donec.</p>
                        <router-link to="/services-details" class="link-btn">Learn More <i class="ph-caret-right"></i></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhatWeOffer'
}
</script>