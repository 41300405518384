<template>
    <div class="features-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span class="sub-title green-color">Our Features</span>
                <h2>Your comfort depends <br>on Vumy</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="features-item">
                        <div class="icon">
                            <i class="ph-first-aid-kit"></i>
                        </div>
                        <h3 class="nunito-font">Medical Treatment</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="features-item">
                        <div class="icon">
                            <i class="ph-info"></i>
                        </div>
                        <h3 class="nunito-font">Emergency Help</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="features-item">
                        <div class="icon">
                            <i class="ph-user-circle-plus"></i>
                        </div>
                        <h3 class="nunito-font">Qualified Doctors</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>