<template>
    <div 
        class="insurance-banner-area movearea"
        @mousemove="onMousemove"
        :style="{ backgroundColor: `hsl(${x}, 99%, 99%)` }"
    >
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="insurance-banner-content">
                        <span class="sub-title" data-aos="fade-up" data-aos-duration="1200">#Life Insurance</span>
                        <h1 data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100" class="nunito-font">We make getting life insurance a breeze</h1>
                        <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">Velit hendrerit sit auctor tempor sem. Congue mi tempor condimentum felis arcu, non cursus. Nulla pharetra porttitor sed platea arcu et leo odio.</p>
                        <div class="btn-box" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                            <router-link to="/contact" class="btn-style-one dark-green-color">Get Started Now <i class="ph-caret-right"></i></router-link>
                            <router-link to="/about-us-two" class="btn-style-one white-color">
                                About Us 
                                <i class="ph-caret-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="insurance-banner-image">
                        <img src="../../assets/images/banner/banner4.png" data-aos="fade-up" data-aos-duration="1200" alt="banner-image">
                        <img src="../../assets/images/shape/shape7.png" class="shape7" data-speed="0.10" data-revert="true" alt="shape">
                    </div>
                </div>
            </div>
        </div>
        <div class="shape6" data-speed="0.10" data-revert="true">
            <img src="../../assets/images/shape/shape6.png" alt="shape">
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    data() {
        return {
            x: 0
        }
    },
    methods: {
        onMousemove(e) {
            this.x = e.clientX
        }
    }
}
</script>