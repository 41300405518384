<template>
    <div>
        <NavbarStyleFour />
        <PageTitle subTitle="About Us" heding="Hello World! This is Vumy!" />
        <About />
        <ProtectYou />
        <ApplyProcess />
        <Testimonials class="pb-0" />
        <Faq />
        <JoinOurCommunity />
        <FooterStyleFour />
    </div>
</template>

<script>
import NavbarStyleFour from '../Layouts/NavbarStyleFour'
import PageTitle from '../Common/PageTitle'
import About from '../AboutTwo/About'
import ProtectYou from '../AboutTwo/ProtectYou'
import ApplyProcess from '../AboutTwo/ApplyProcess'
import Testimonials from '../AboutTwo/Testimonials'
import Faq from '../Common/Faq'
import JoinOurCommunity from '../AboutTwo/JoinOurCommunity'
import FooterStyleFour from '../Layouts/FooterStyleFour'

export default {
    name: 'AboutTwoPage',
    components: {
        NavbarStyleFour,
        PageTitle,
        About,
        ProtectYou,
        ApplyProcess,
        Testimonials,
        Faq,
        JoinOurCommunity,
        FooterStyleFour,
    }
}
</script>