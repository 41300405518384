<template>
    <div class="template-footer-nine pt-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 col-sm-5">
                    <div class="single-footer-widget">
                        <router-link to="/" class="logo">
                            <img src="../../assets/images/logo.png" alt="logo">
                        </router-link>
                        <p>Lorem ipsum dolor sit amet, consectetur, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua minim.</p>
                        <ul class="social-links">
                            <li>
                                <a href="https://www.https://www.linkedin.com/.com/" target="_blank">
                                    <i class="flaticon-facebook-app-symbol"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.twitter.com/" target="_blank">
                                    <i class="flaticon-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/" target="_blank">
                                    <i class="flaticon-linkedin"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/" target="_blank">
                                    <i class="flaticon-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-3">
                    <div class="single-footer-widget pl-2">
                        <h3>Company</h3>
                        <ul class="links-list">
                            <li><router-link to="/about-us">About Us</router-link></li>
                            <li><router-link to="/services">Core Services</router-link></li>
                            <li><router-link to="/privacy-policy">Refund Policy</router-link></li>
                            <li><router-link to="/faq">FAQ's</router-link></li>
                            <li><router-link to="/testimonials">Reviews</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-4">
                    <div class="single-footer-widget">
                        <h3>Useful Links</h3>
                        <ul class="links-list">
                            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            <li><router-link to="/privacy-policy">Return Policy</router-link></li>
                            <li><router-link to="/terms-conditions">Terms & Conditions</router-link></li>
                            <li><router-link to="/contact">How It Works?</router-link></li>
                            <li><router-link to="/contact">Contact Us</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-4">
                    <div class="single-footer-widget">
                        <h3>Support</h3>
                        <ul class="links-list">
                            <li><router-link to="/services">Services</router-link></li>
                            <li><router-link to="/contact">Support</router-link></li>
                            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            <li><router-link to="/faq">FAQ's</router-link></li>
                            <li><router-link to="/contact">Contact</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-8">
                    <div class="single-footer-widget">
                        <h3>Newsletter</h3>
                        <p>Best solution for your it startup.</p>
                        <form class="newsletter-form" @submit.prevent>
                            <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL">
                            <button type="submit" class="btn-style-one red-light-color">Subscribe Now <i class="ph-caret-right"></i></button>
                        </form>
                    </div>
                </div>
            </div>

            <div class="copyright-area">
                <p>
                    Copyright @{{currentYear}} Vumy. All Rights Reserved by 
                    <a href="https://envytheme.com/" target="_blank">EnvyTheme</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterStyleNine',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>