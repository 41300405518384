<template>
    <div>
        <Navbar />
        <PageTitle subTitle="Feedback" heding="Our Latest Testimonials" />
        <Testimonials />
        <TestimonialsOne />
        <TestimonialsTwo />
        <TestimonialsThree />
        <TestimonialsFour />
        <TestimonialsFive />
        <FooterStyleTwo />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Testimonials from '../Testimonials/Testimonials'
import TestimonialsOne from '../Testimonials/TestimonialsOne'
import TestimonialsTwo from '../Testimonials/TestimonialsTwo'
import TestimonialsThree from '../Testimonials/TestimonialsThree'
import TestimonialsFour from '../Testimonials/TestimonialsFour'
import TestimonialsFive from '../Testimonials/TestimonialsFive'
import FooterStyleTwo from '../Layouts/FooterStyleTwo'

export default {
    name: 'TestimonialsPage',
    components: {
        Navbar,
        PageTitle,
        Testimonials,
        TestimonialsOne,
        TestimonialsTwo,
        TestimonialsThree,
        TestimonialsFour,
        TestimonialsFive,
        FooterStyleTwo,
    }
}
</script>