<template>
    <div class="case-studies-area with-top-border pt-100 bg-color">
        <div class="container">
            <div class="section-title" data-aos="fade-up" data-aos-duration="1200">
                <span class="sub-title">Case Studies</span>
                <h2>We’ve done lot’s of work, Let’s check some</h2>
            </div>
        </div>
        <div class="container-fluid">
            <div class="case-studies-slides">
                <carousel
                    :autoplay="5000"
                    :wrap-around="true"
                    :breakpoints="breakpoints"
                >
                    <Slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="single-case-studies-box">
                            <router-link to="/portfolio-details" class="d-block image">
                                <img :src="slide.image" alt="case-studies-image">
                            </router-link>
                            <div class="content">
                                <div class="icon">
                                    <i :class="slide.icon"></i>
                                </div>
                                <h3>
                                    <router-link to="/portfolio-details">{{slide.title}}</router-link>
                                </h3>
                                <p>{{slide.desc}}</p>
                                <router-link to="/portfolio-details" class="link-btn">
                                    Learn More 
                                    <i class="ph-caret-right"></i>
                                </router-link>
                            </div>
                        </div>
                    </Slide>

                    <template #addons>
                        <navigation>
                            <template #next>
                                <i class='ph-arrow-right'></i>
                            </template>
                            <template #prev>
                                <i class='ph-arrow-left'></i>
                            </template>
                        </navigation>
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'CaseStudies',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/case-studies/case-studies1.jpg'),
                icon: 'flaticon-startup',
                title: 'Business Startup',
                desc: 'Lorem ipsum dolor sit amet, consec tetur adipis cing elit. Sed est non feugiat sagi ttis donec dolor sit.',
            },
            {
                id: 2,
                image: require('../../assets/images/case-studies/case-studies2.jpg'),
                icon: 'flaticon-consulting',
                title: 'Finance Consulting',
                desc: 'Lorem ipsum dolor sit amet, consec tetur adipis cing elit. Sed est non feugiat sagi ttis donec dolor sit.',
            },
            {
                id: 3,
                image: require('../../assets/images/case-studies/case-studies3.jpg'),
                icon: 'flaticon-personal-wealth',
                title: 'Wealth Management',
                desc: 'Lorem ipsum dolor sit amet, consec tetur adipis cing elit. Sed est non feugiat sagi ttis donec dolor sit.',
            },
            {
                id: 4,
                image: require('../../assets/images/case-studies/case-studies4.jpg'),
                icon: 'flaticon-management',
                title: 'Business Planning',
                desc: 'Lorem ipsum dolor sit amet, consec tetur adipis cing elit. Sed est non feugiat sagi ttis donec dolor sit.',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
			},
            576: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 3,
                snapAlign: 'left',
            },
        },
    }),
})
</script>